<template>
  <div id="app" class="container">
    <h1>Blackjack Edge Calculator</h1>
<h2 :class="edgeColorClass">
  Player's Edge: {{ playerEdge.toFixed(3) }}% </h2><h4 :class="edgeColorClass">
      | Optimal Kelly Bet: {{ betAmount.toFixed(3) }}% of bankroll
</h4><h4> Decks remaining:  {{ deckRemain.toFixed(1) }}</h4>
    <div class="input-group">
      <input 
        type="text" 
        v-model="userInput" 
        @keyup.enter="handleUserInput" 
        placeholder="Enter card sequence [2,3,4,5,6,7,8,9,0,J,Q,K] or reshuffled deck [=] or [help]" 
        class="input-field"
      >
      <button @click="handleUserInput" class="button-container">Submit</button>
    </div>
    
    <div class="messages">
      <h2>Session Log</h2>
      <ul>
          <li v-for="message in messages" :key="message.id" class="message-item">
            {{ message.text }}
          </li>
        </ul>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.title = "CounterPro rev 10/29/24";
  },
  data() {
    return {
      totalDecks: 4,
      adjustedEORsBy1000: {
        '2': -4, '3': -4.3, '4': -5.2, '5': -6.7, '6': -4.5,
        '7': -3, '8': 0, '9': -0.1, '0': 5.1, 'j': 5.1, 'q': 5.1, 'k': 5.1, 'a': 5.9
      },
      totalCardsPerRank: {},
      currentCardsPerRank: {},
      historyCards: [],
      currentRoundCards: [],
      myHand: [],
      otherPlayersHand: [],
      dealerHand: [],
      decksRemaining: 4,
      messages: [],
      userInput: ''
    };
  },
  created() {
    this.initializeCards();
  },
    computed: {
    deckRemain(){
      this.calculateDecksRemaining();
      return this.decksRemaining;
    },
    playerEdge() {
      this.calculateDecksRemaining();
      let edge = 0;
      Object.keys(this.adjustedEORsBy1000).forEach(card => {
        const expectedCount = this.totalCardsPerRank[card] / this.totalDecks * this.decksRemaining;
        const actualCount = this.currentCardsPerRank[card];
        const excess = actualCount - expectedCount;
        edge += (this.adjustedEORsBy1000[card] / 1000) * excess;
      });
      return (edge - 0.004)*100; // adjust for default decks
    },
    edgeColorClass() {
      if (this.playerEdge > 5) {
        return 'gold';
       } else if (this.playerEdge >= 3.5) {
        return 'light-green';
      } else if (this.playerEdge >= 1.8) {
        return 'green';
      } else if (this.playerEdge < 0) {
        return 'red';
      } else {
        return '';
      }},
    betAmount() {
      const variance = 1.14; // Variance per unit bet
      return this.playerEdge / variance;
    }
  },
  methods: {
    initializeCards() {
      const ranks = ['2', '3', '4', '5', '6', '7', '8', '9', '0', 'j', 'q', 'k', 'a'];
      ranks.forEach(rank => {
        this.totalCardsPerRank[rank] = this.totalDecks * 4;
      });
      this.currentCardsPerRank = {...this.totalCardsPerRank};
    },
    parseCards(cardString) {
      return cardString.split('').filter(char => '23456789jqka0'.includes(char));
    },
    updateCount(cards) {
      cards.forEach(card => {
        card = card.toLowerCase();
        if (card in this.currentCardsPerRank) {
          this.currentCardsPerRank[card] = Math.max(this.currentCardsPerRank[card] - 1, 0);
        }
        this.currentRoundCards.push(card);
      });
    },
    calculateDecksRemaining() {
      const totalCardsRemaining = Object.values(this.currentCardsPerRank).reduce((a, b) => a + b, 0);
      this.decksRemaining = totalCardsRemaining / 52;
    },
    getPlayerEdge() {
      this.calculateDecksRemaining();
      let playerEdge = 0;
      Object.keys(this.adjustedEORsBy1000).forEach(card => {
        const expectedCount = this.totalCardsPerRank[card] / this.totalDecks * this.decksRemaining;
        const actualCount = this.currentCardsPerRank[card];
        const excess = actualCount - expectedCount;
        playerEdge += (this.adjustedEORsBy1000[card] / 1000) * excess;
      });
      return playerEdge - 0.004; // adjust for default decks
    },
    handleUserInput() {
  // console.log("Input received:", this.userInput); // Verify input reception
  const input = this.userInput.trim();
    this.messages.push({ id: this.messages.length, text: input });
  this.userInput = ''; // Clear input field after capturing the value
  switch (input) {
    // case '\\':
    //   this.endRound();
    //   this.messages.push({ id: this.messages.length, text: "New round started." });
    //   console.log("Messages array:", this.messages); // Check if the message is being added
    //   break;
        case 'help': {
          this.shuffleCards();
          this.messages.push({ id: this.messages.length, text: "This is optimized for RuneWild's blackjack rules. Edge indicates the likelihood of J, Q, K, 10, A on next draw. Assumes basic strategy and bets pre-deal." });
          this.messages.push({ id: this.messages.length, text: "Zoom enhances text. [cls] clears log. Enjoy gp." });
          break;
        }
        case '=': {
          this.shuffleCards();
          this.messages.push({ id: this.messages.length, text: "Cards shuffled." });
          break;
        }
        case '-': {
          const playerEdge = this.getPlayerEdge();
          const betAmount = this.getBetAmount(playerEdge);
          this.messages.push({
            id: this.messages.length,
            text: `Player's Edge: ${(playerEdge * 100).toFixed(2)}%\nOptimal Kelly Bet: ${betAmount.toFixed(7)}`
          });
          break;
        }
        case 'cls': {
          this.messages=[];
          break;
        }
        default: {
          const cards = this.parseCards(input);
          if (input[0] === '[' || input[0] === '{') {
            this.addCardsToDealer(cards);
          } else if (input[0] === ']' || input[0] === '}') {
            this.addCardsToMyHand(cards);
          } else {
            this.addCardsToOtherPlayersHand(cards);
          }
          break;
        }
      }
    },
    getBetAmount(playerEdge) {
      const variance = 1.14; // Variance per unit bet
      return playerEdge / variance;
    },
    addCardsToMyHand(cards) {
      this.myHand.push(...cards);
      this.updateCount(cards);
    },
    addCardsToDealer(cards) {
      this.dealerHand.push(...cards);
      this.updateCount(cards);
    },
    addCardsToOtherPlayersHand(cards) {
      this.otherPlayersHand.push(...cards);
      this.updateCount(cards);
    },
    endRound() {
      this.historyCards.push(...this.currentRoundCards);
      this.currentRoundCards = [];
      this.myHand = [];
      this.otherPlayersHand = [];
      this.dealerHand = [];
    },
    shuffleCards() {
      this.currentCardsPerRank = {...this.totalCardsPerRank};
      this.historyCards = [];
      this.currentRoundCards = [];
      this.myHand = [];
      this.otherPlayersHand = [];
      this.dealerHand = [];
      this.decksRemaining = this.totalDecks;
    }
  }
}
</script>

<style>
.button-container {
  gap: 1000px; /* Even spacing between buttons */
}
.gold {
  color: gold;
}
.green {
  color: green;
}
.light-green {
  color: lightgreen;
}
.red {
  color: red;
}
#app {
  max-width: 600px;
  margin: 20px auto;
  font-family: 'Courier New', Courier, monospace;
  background-color: #333;
  color: #fff;
  padding: 20px;
}
input {
  width: 100%;
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px;
  font-size: 16px;
}
input:focus {
  outline: none;
}
h1 {
  margin-bottom: 10px;
  color: #f0f0f0;  
}
body {
  background-color: #000; /* Black background for the whole page */
}
</style>